import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface ShowMouseType {
  showMouse: any
}

const initialState: ShowMouseType = {
  showMouse: true
}

export default createReducer(initialState, (builder) =>
  builder.addCase(actions.setShowMouse, (state, { payload }) => {
    state.showMouse = payload
  }),
)
