import React from "react";
import styled, { keyframes } from "styled-components";
import { SpinnerProps } from "./types";

const Container = styled.div<{ isLoading: boolean }>`
  width: 100%;
  z-index: 9999;
  position: fixed;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;

  video {
    margin-top: -2rem;
  }

  ${({ isLoading }) =>
    isLoading
      ? `
  opacity: 1; height: 100%;

  img{
    height: auto;
  }
  `
      : `
  opacity: 0; height: 0;

  img{
    height: 0;
  }
  `}
`;

const Spinner: React.FC<SpinnerProps> = ({ isLoading = false, size = 128 }) => {
  return (
    <Container isLoading={isLoading}>
      <video width="100%" height="15%" autoPlay muted loop playsInline>
        <source src="/videos/spinner.mp4" type="video/mp4" />
      </video>
    </Container>
  );
};

export default Spinner;
