import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Digitalt';
    src: local('Digitalt'), url('/fonts/Digitalt/Digitalt.ttf') format('truetype');
  }

  @font-face {
    font-family: Familjen-Grotesk;
    src: url('/fonts/Familjen-Grotesk/FamiljenGrotesk-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'FamiljenGrotesk-Medium';
    src: url('/fonts/Familjen-Grotesk/FamiljenGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'FamiljenGrotesk-SemiBold';
    src: url('/fonts/Familjen-Grotesk/FamiljenGrotesk-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter'), url('/fonts/Inter/Inter-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: local('Inter'), url('/fonts/Inter/Inter-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: local('Inter'), url('/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: bold;
    src: local('Inter'), url('/fonts/Inter/Inter-Bold.ttf') format('truetype');
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};
    font-family: Familjen-Grotesk;

    img {
      height: auto;
    }

    top: 0 !important;
  }

  html.lenis{height:auto}.lenis.lenis-smooth{scroll-behavior:auto}.lenis.lenis-smooth [data-lenis-prevent]{overscroll-behavior:contain}.lenis.lenis-stopped{overflow:hidden}.lenis.lenis-scrolling iframe{pointer-events:none}
  
  .container {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    max-width: 1300px;
    padding: 0 14px;
    margin: 0 auto;
  }

  .underline {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    background: linear-gradient(99.46deg, #fa00ff -10.9%, #00e0ff 97.13%, #2ad4f9 97.14%);
    height: 2px;
    width: 100%;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .error {
    font-size: 12px;
    color: #FF4842;
  }

  .ant-layout{
    overflow-x: hidden;
  }

  // ant select dropdown
  .ant-form-item {
    margin: 0;
  }
  .ant-select-dropdown {
    padding: 0; 
    background: #0C131F; 
    border-radius: 4px; 
    overflow: hidden;
  }
  .ant-select-item {
    color: rgb(230 247 255); 
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: rgb(230 247 255); 
    background: #83ccf045; 
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff; 
    background: #83ccf045; 
  }
  .ant-select-arrow {
    img {
      transform: rotate(180deg);
    }
  }
  .ant-select-open .ant-select-arrow {
    img {
      transform: rotate(0deg);
    }
  }

  #google_translate_element, .skiptranslate iframe{
    display: none;
  }

  .ReactModal__Overlay{
    z-index: 999;
  }

  .modal{
    width: 80%;
    background: #fff;
    height: 80%;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
  }

  .member-modal{
    width: 90%;
    height: 90%;
    max-width: 700px;

    ${({ theme }) => theme.mediaQueries.sm} {
      width: 80%;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      width: 50%;
      height: fit-content;
    }
  }

  .close-btn{
    img{
      width: 15px;
    }
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 999;
    cursor: pointer;
  }

  .pointer{
    cursor: pointer;
  }

  .mb-flex-col-reverse{
    @media (max-width: 991px){
      flex-direction: column-reverse;
    }
  }

  .mb-txt-center{
    text-align: center;
    ${({ theme }) => theme.mediaQueries.lg} {
      text-align: left;
    }
  }

  .w-full{
    width: 100%;
  }

  .tooltip-footer{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #6D6D6D;
    margin-bottom: 0;
  }

  .share-bottom{
    padding: 7px 10px;
    .share-bottom-item{
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }

    img {
      filter: invert(76%) sepia(0%) saturate(67%) hue-rotate(161deg) brightness(82%) contrast(84%);
      opacity: 0.8;
      width: 20px;
    }
  }

  .first-uppercase-text{
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .read-more-button{
    position: relative;
    overflow: hidden;
    span {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transform: translateX(-100%);
      transition: transform 275ms ease;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      overflow: hidden;
      &::before {
        display: inline-block;
        content: attr(data-content);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: var(--Main-blue, #006DFE);
        color: #fff;
        transform: translateX(100%);
        transition: transform 275ms ease;
      }
    }


    &:hover {
      border: 1px solid transparent !important;
      span {
        transform: translateX(0);
        &::before {
          transform: translateX(0);
        }
      }
    }

    &.no-border:hover{
      border: none !important;
    }
  }
`

export default GlobalStyle
