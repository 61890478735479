import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      {...props}
      viewBox="0 0 89 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M34.1602 28.7085L40.4906 9.92188H43.9995L50.33 28.7085H47.2914L45.9168 24.5127H38.6096L37.1988 28.7085H34.1602ZM39.4054 21.8667H45.0124L41.8291 12.0387H42.6249L39.4054 21.8667Z"
        fill="black"
      />
      <path
        d="M58.2161 29.0118C57.0947 29.0118 56.0457 28.785 55.069 28.3314C54.0923 27.8778 53.2964 27.273 52.6091 26.517C51.9218 25.761 51.4515 24.8538 51.126 23.8332L53.5496 22.7748C53.9837 23.9466 54.6349 24.816 55.4669 25.4586C56.2989 26.0634 57.2756 26.4036 58.3608 26.4036C59.0119 26.4036 59.5546 26.2902 60.061 26.1012C60.5313 25.8744 60.9292 25.6098 61.1824 25.194C61.4356 24.816 61.5803 24.3624 61.5803 23.871C61.5803 23.1906 61.3994 22.6236 61.0377 22.2456C60.676 21.8298 60.1333 21.5274 59.4099 21.3006L56.0457 20.2044C54.7072 19.7508 53.6582 19.1082 52.9709 18.201C52.2474 17.2938 51.9218 16.2354 51.9218 15.0258C51.9218 13.9674 52.175 13.0224 52.6815 12.2286C53.1879 11.4348 53.8752 10.7922 54.7434 10.3386C55.6116 9.885 56.6244 9.6582 57.7458 9.6582C58.8311 9.6582 59.8078 9.8472 60.676 10.263C61.5441 10.641 62.3038 11.208 62.9549 11.8884C63.6061 12.5688 64.0401 13.3626 64.3295 14.2698L61.9782 15.366C61.6165 14.3832 61.0739 13.6272 60.3142 13.0602C59.5546 12.531 58.6864 12.2664 57.7097 12.2664C57.1309 12.2664 56.5883 12.3798 56.1542 12.5688C55.7201 12.7956 55.3584 13.098 55.1051 13.476C54.8519 13.854 54.7434 14.3454 54.7434 14.8368C54.7434 15.4416 54.9243 15.9708 55.286 16.4622C55.6477 16.9158 56.2265 17.2938 56.9862 17.5206L60.1333 18.5412C61.5803 18.9948 62.6294 19.6374 63.3528 20.5068C64.0763 21.3384 64.4381 22.3968 64.4381 23.682C64.4381 24.7404 64.1848 25.6476 63.6422 26.4414C63.1358 27.2352 62.4123 27.8778 61.4718 28.3314C60.5313 28.785 59.4099 29.0118 58.2161 29.0118Z"
        fill="black"
      />
      <path
        d="M67.7656 28.7085V12.5679L70.6234 9.92188H73.662C75.5431 9.92188 77.1347 10.2999 78.5093 11.0937C79.884 11.8875 80.933 12.9837 81.6927 14.3823C82.4523 15.7809 82.8141 17.4441 82.8141 19.2963C82.8141 21.1485 82.4523 22.7739 81.6927 24.2103C80.933 25.6467 79.884 26.7429 78.5093 27.5367C77.1347 28.3305 75.5069 28.7085 73.662 28.7085H67.7656ZM70.6234 26.0625H73.6982C74.9643 26.0625 76.0857 25.7979 77.0262 25.2309C77.9667 24.6639 78.6902 23.9079 79.1967 22.8873C79.7031 21.8667 79.9563 20.6949 79.9563 19.2963C79.9563 17.9355 79.7031 16.7259 79.1605 15.7431C78.654 14.7603 77.8944 13.9665 76.99 13.3995C76.0495 12.8325 74.9643 12.5679 73.6982 12.5679H70.6234V26.0625Z"
        fill="black"
      />
      <path
        d="M86.1787 28.5203V12.1151C86.1787 12.0395 86.2149 12.0017 86.2511 11.9639L88.7471 10.1117C88.8556 10.0361 89.0003 10.1117 89.0003 10.2629V28.5203C89.0003 28.6337 88.9279 28.7093 88.8194 28.7093H86.3234C86.2511 28.7093 86.1787 28.6337 86.1787 28.5203Z"
        fill="black"
      />
      <path
        d="M35.8602 33.586H34.8111L34.6303 34.153H34.3047L35.1729 31.6582H35.5346L36.4028 34.153H36.0772L35.8602 33.586ZM35.7517 33.3214L35.3176 32.0362L34.8835 33.3214H35.7517Z"
        fill="black"
      />
      <path
        d="M37.018 34.116C36.9095 34.0782 36.801 34.0026 36.7286 33.8892C36.6563 33.8136 36.6201 33.7002 36.6201 33.5868H36.9457C36.9457 33.7002 36.9819 33.7758 37.0904 33.8136C37.1627 33.8892 37.2713 33.8892 37.4159 33.8892C37.5245 33.8892 37.633 33.8514 37.7053 33.8136C37.7777 33.7758 37.8139 33.7002 37.8139 33.5868C37.8139 33.5112 37.7777 33.4356 37.7053 33.3978C37.633 33.36 37.5245 33.3222 37.3798 33.2844C37.2351 33.2466 37.1266 33.2088 37.0542 33.171C36.9819 33.1332 36.8733 33.0576 36.8372 32.982C36.7648 32.9064 36.7286 32.793 36.7286 32.6796C36.7286 32.5662 36.7648 32.4906 36.801 32.415C36.8733 32.3394 36.9457 32.2638 37.0542 32.226C37.1627 32.1882 37.2713 32.1504 37.4159 32.1504C37.633 32.1504 37.7777 32.1882 37.9224 32.3016C38.0671 32.415 38.1033 32.5662 38.1394 32.7552H37.8139C37.8139 32.6418 37.7777 32.5662 37.7053 32.4906C37.633 32.415 37.5245 32.415 37.4159 32.415C37.3074 32.415 37.1989 32.4528 37.1627 32.4906C37.0904 32.5284 37.0542 32.604 37.0542 32.6796C37.0542 32.7552 37.0904 32.793 37.1266 32.8308C37.1627 32.8686 37.2351 32.9064 37.2713 32.9442C37.3436 32.982 37.4159 32.982 37.5245 33.0198C37.6692 33.0576 37.7777 33.0954 37.85 33.1332C37.9224 33.171 37.9947 33.2088 38.0671 33.2844C38.1394 33.36 38.1756 33.4734 38.1756 33.5868C38.1756 33.7002 38.1394 33.7758 38.1033 33.8892C38.0309 33.9648 37.9586 34.0404 37.85 34.0782C37.7415 34.116 37.633 34.1538 37.4883 34.1538C37.2713 34.1916 37.1627 34.1538 37.018 34.116Z"
        fill="black"
      />
      <path
        d="M38.8266 34.116C38.7181 34.0782 38.6096 34.0026 38.5372 33.8892C38.4649 33.8136 38.4287 33.7002 38.4287 33.5868H38.7543C38.7543 33.7002 38.7905 33.7758 38.899 33.8136C38.9713 33.8892 39.0798 33.8892 39.2245 33.8892C39.3331 33.8892 39.4416 33.8514 39.5139 33.8136C39.5863 33.7758 39.6225 33.7002 39.6225 33.5868C39.6225 33.5112 39.5863 33.4356 39.5139 33.3978C39.4416 33.36 39.3331 33.3222 39.1884 33.2844C39.0437 33.2466 38.9352 33.2088 38.8628 33.171C38.7905 33.1332 38.6819 33.0576 38.6458 32.982C38.5734 32.9064 38.5372 32.793 38.5372 32.6796C38.5372 32.5662 38.5734 32.4906 38.6096 32.415C38.6819 32.3394 38.7543 32.2638 38.8628 32.226C38.9713 32.1882 39.0798 32.1504 39.2245 32.1504C39.4416 32.1504 39.5863 32.1882 39.731 32.3016C39.8757 32.415 39.9119 32.5662 39.948 32.7552H39.6225C39.6225 32.6418 39.5863 32.5662 39.5139 32.4906C39.4416 32.415 39.3331 32.415 39.2245 32.415C39.116 32.415 39.0075 32.4528 38.9713 32.4906C38.899 32.5284 38.8628 32.604 38.8628 32.6796C38.8628 32.7552 38.899 32.793 38.9351 32.8308C38.9713 32.8686 39.0437 32.9064 39.0798 32.9442C39.1522 32.982 39.2245 32.982 39.3331 33.0198C39.4778 33.0576 39.5863 33.0954 39.6586 33.1332C39.731 33.171 39.8033 33.2088 39.8757 33.2844C39.948 33.36 39.9842 33.4734 39.9842 33.5868C39.9842 33.7002 39.948 33.7758 39.9119 33.8892C39.8395 33.9648 39.7672 34.0404 39.6586 34.0782C39.5501 34.116 39.4416 34.1538 39.2969 34.1538C39.0798 34.1916 38.9351 34.1538 38.8266 34.116Z"
        fill="black"
      />
      <path
        d="M42.0822 33.2837H40.5629C40.5629 33.4727 40.6352 33.6239 40.7437 33.7373C40.8523 33.8507 40.997 33.8885 41.1778 33.8885C41.3225 33.8885 41.4311 33.8507 41.5034 33.7751C41.6119 33.6995 41.6481 33.6239 41.6843 33.5105H42.0098C41.9737 33.6995 41.8651 33.8507 41.7204 33.9641C41.5757 34.0775 41.3949 34.1531 41.1417 34.1531C40.9608 34.1531 40.8161 34.1153 40.6714 34.0397C40.5267 33.9641 40.4182 33.8507 40.3458 33.6995C40.2735 33.5483 40.2373 33.3593 40.2373 33.1703C40.2373 32.9813 40.2735 32.7923 40.3458 32.6411C40.4182 32.4899 40.5267 32.3765 40.6714 32.3009C40.8161 32.2253 40.9608 32.1875 41.1417 32.1875C41.3225 32.1875 41.4672 32.2253 41.6119 32.3009C41.7566 32.3765 41.8651 32.4899 41.9375 32.6411C42.0098 32.7923 42.046 32.9435 42.046 33.1325C42.0822 33.1325 42.0822 33.2081 42.0822 33.2837ZM41.6843 32.6789C41.6481 32.6033 41.5757 32.5277 41.4672 32.4899C41.3587 32.4521 41.2864 32.4143 41.1778 32.4143C41.0331 32.4143 40.8884 32.4521 40.7799 32.5655C40.6714 32.6789 40.599 32.8301 40.599 33.0191H41.7928C41.7566 32.9057 41.7204 32.7923 41.6843 32.6789Z"
        fill="black"
      />
      <path
        d="M42.8781 32.4533V33.6251C42.8781 33.7385 42.9143 33.7763 42.9504 33.8141C42.9866 33.8519 43.059 33.8897 43.1675 33.8897H43.3845V34.1543H43.0951C42.9143 34.1543 42.8058 34.1165 42.6972 34.0409C42.6249 33.9653 42.5525 33.8141 42.5525 33.6251V32.4533H42.2993V32.1887H42.5525V31.6973H42.8781V32.1887H43.3845V32.4533H42.8781Z"
        fill="black"
      />
      <path
        d="M46.0974 31.7738C46.2782 31.8872 46.4229 32.0384 46.5314 32.2274C46.64 32.4164 46.6761 32.6432 46.6761 32.9078C46.6761 33.1724 46.64 33.3992 46.5314 33.5882C46.4229 33.7772 46.2782 33.9284 46.0974 34.004C45.9165 34.0796 45.6633 34.1552 45.41 34.1552H44.6504V31.6604H45.41C45.6633 31.6226 45.9165 31.6982 46.0974 31.7738ZM46.1335 33.626C46.3144 33.437 46.3868 33.2102 46.3868 32.9078C46.3868 32.6054 46.3144 32.3408 46.1335 32.1896C45.9527 32.0384 45.7356 31.925 45.41 31.925H44.976V33.8906H45.41C45.7356 33.8906 45.9527 33.7772 46.1335 33.626Z"
        fill="black"
      />
      <path
        d="M47.1104 31.7725C47.0743 31.7347 47.0381 31.6591 47.0381 31.6213C47.0381 31.5835 47.0743 31.5079 47.1104 31.4701C47.1466 31.4323 47.219 31.3945 47.2551 31.3945C47.3275 31.3945 47.3637 31.4323 47.3998 31.4701C47.436 31.5079 47.4722 31.5835 47.4722 31.6213C47.4722 31.6591 47.436 31.7347 47.3998 31.7725C47.3637 31.8103 47.3275 31.8481 47.2551 31.8481C47.219 31.8481 47.1466 31.8481 47.1104 31.7725ZM47.3998 32.1883V34.1539H47.0743V32.1883H47.3998Z"
        fill="black"
      />
      <path
        d="M49.1723 32.2638C49.2809 32.3394 49.3894 32.415 49.4617 32.5284V32.1504H49.7873V34.1538C49.7873 34.3428 49.7511 34.494 49.6788 34.6452C49.6064 34.7964 49.4979 34.9098 49.3532 34.9854C49.2085 35.061 49.0638 35.0988 48.883 35.0988C48.6297 35.0988 48.4127 35.0232 48.268 34.9098C48.0871 34.7964 48.0148 34.6074 47.9786 34.4184H48.3042C48.3403 34.5318 48.4127 34.6452 48.5212 34.7208C48.6297 34.7964 48.7744 34.8342 48.9191 34.8342C49.1 34.8342 49.2447 34.7964 49.3532 34.683C49.4617 34.5696 49.5341 34.4184 49.5341 34.1916V33.7758C49.4617 33.8892 49.3894 34.0026 49.2447 34.0782C49.1 34.1538 48.9915 34.1916 48.8106 34.1916C48.6297 34.1916 48.485 34.1538 48.3403 34.0782C48.1956 34.0026 48.0871 33.8892 48.0148 33.7002C47.9424 33.5112 47.9062 33.36 47.9062 33.171C47.9062 32.982 47.9424 32.793 48.0148 32.6418C48.0871 32.4906 48.1956 32.3772 48.3403 32.3016C48.485 32.226 48.6297 32.1882 48.8106 32.1882C48.883 32.1504 49.0276 32.1882 49.1723 32.2638ZM49.3532 32.7552C49.2809 32.6418 49.2085 32.5662 49.1362 32.4906C49.0276 32.415 48.9191 32.415 48.8106 32.415C48.7021 32.415 48.5936 32.4528 48.485 32.4906C48.3765 32.5662 48.3042 32.6418 48.268 32.7552C48.1956 32.8686 48.1956 32.982 48.1956 33.1332C48.1956 33.2844 48.2318 33.3978 48.268 33.5112C48.3403 33.6246 48.4127 33.7002 48.485 33.7758C48.5936 33.8514 48.7021 33.8514 48.8106 33.8514C48.9191 33.8514 49.0276 33.8136 49.1362 33.7758C49.2447 33.7002 49.317 33.6246 49.3532 33.5112C49.4256 33.3978 49.4256 33.2844 49.4256 33.1332C49.4256 32.982 49.3894 32.8686 49.3532 32.7552Z"
        fill="black"
      />
      <path
        d="M50.2935 31.7725C50.2574 31.7347 50.2212 31.6591 50.2212 31.6213C50.2212 31.5835 50.2574 31.5079 50.2935 31.4701C50.3297 31.4323 50.4021 31.3945 50.4382 31.3945C50.5106 31.3945 50.5468 31.4323 50.5829 31.4701C50.6191 31.5079 50.6553 31.5835 50.6553 31.6213C50.6553 31.6591 50.6191 31.7347 50.5829 31.7725C50.5468 31.8103 50.5106 31.8481 50.4382 31.8481C50.3659 31.8481 50.3297 31.8481 50.2935 31.7725ZM50.5829 32.1883V34.1539H50.2574V32.1883H50.5829Z"
        fill="black"
      />
      <path
        d="M51.5236 32.4533V33.6251C51.5236 33.7385 51.5598 33.7763 51.596 33.8141C51.6321 33.8519 51.7045 33.8897 51.813 33.8897H52.03V34.1543H51.7407C51.5598 34.1543 51.4513 34.1165 51.3427 34.0409C51.2704 33.9653 51.198 33.8141 51.198 33.6251V32.4533H50.9448V32.1887H51.198V31.6973H51.5236V32.1887H52.03V32.4533H51.5236Z"
        fill="black"
      />
      <path
        d="M52.3916 32.604C52.4639 32.4528 52.5725 32.3394 52.7172 32.2638C52.8618 32.1882 53.0065 32.1504 53.1874 32.1504C53.3683 32.1504 53.513 32.1882 53.6215 32.2638C53.73 32.3394 53.8385 32.415 53.9109 32.5284V32.1504H54.2365V34.116H53.9109V33.738C53.8385 33.8514 53.7662 33.9648 53.6215 34.0404C53.513 34.116 53.3683 34.1538 53.1874 34.1538C53.0065 34.1538 52.8618 34.116 52.7172 34.0404C52.5725 33.9648 52.4639 33.8514 52.3916 33.6624C52.3192 33.4734 52.2831 33.3222 52.2831 33.1332C52.2469 32.9442 52.3192 32.7552 52.3916 32.604ZM53.7662 32.7552C53.6939 32.6418 53.6215 32.5662 53.5492 32.4906C53.4406 32.415 53.3321 32.415 53.2236 32.415C53.1151 32.415 53.0065 32.4528 52.898 32.4906C52.7895 32.5662 52.7172 32.6418 52.681 32.7552C52.6086 32.8686 52.6086 32.982 52.6086 33.1332C52.6086 33.2844 52.6448 33.3978 52.681 33.5112C52.7533 33.6246 52.8257 33.7002 52.898 33.7758C53.0065 33.8514 53.1151 33.8514 53.2236 33.8514C53.3321 33.8514 53.4406 33.8136 53.5492 33.7758C53.6577 33.7002 53.73 33.6246 53.7662 33.5112C53.8385 33.3978 53.8385 33.2844 53.8385 33.1332C53.8385 32.982 53.8385 32.8686 53.7662 32.7552Z"
        fill="black"
      />
      <path d="M55.0326 31.4688V34.1525H54.707V31.4688H55.0326Z" fill="black" />
      <path
        d="M55.5753 31.7725C55.5391 31.7347 55.5029 31.6591 55.5029 31.6213C55.5029 31.5835 55.5391 31.5079 55.5753 31.4701C55.6115 31.4323 55.6838 31.3945 55.72 31.3945C55.7923 31.3945 55.8285 31.4323 55.8647 31.4701C55.9008 31.5079 55.937 31.5835 55.937 31.6213C55.937 31.6591 55.9008 31.7347 55.8647 31.7725C55.8285 31.8103 55.7923 31.8481 55.72 31.8481C55.6476 31.8481 55.6115 31.8481 55.5753 31.7725ZM55.8647 32.1883V34.1539H55.5391V32.1883H55.8647Z"
        fill="black"
      />
      <path
        d="M56.6239 33.8885H57.5645V34.1531H56.2622V33.8885L57.1666 32.4521H56.2622V32.1875H57.5283V32.4521L56.6239 33.8885Z"
        fill="black"
      />
      <path
        d="M57.9625 32.604C58.0349 32.4528 58.1434 32.3394 58.2881 32.2638C58.4328 32.1882 58.5775 32.1504 58.7584 32.1504C58.9392 32.1504 59.0839 32.1882 59.1924 32.2638C59.301 32.3394 59.4095 32.415 59.4818 32.5284V32.1504H59.8074V34.116H59.4818V33.738C59.4095 33.8514 59.3371 33.9648 59.1924 34.0404C59.0839 34.116 58.9392 34.1538 58.7584 34.1538C58.5775 34.1538 58.4328 34.116 58.2881 34.0404C58.1434 33.9648 58.0349 33.8514 57.9625 33.6624C57.8902 33.4734 57.854 33.3222 57.854 33.1332C57.854 32.9442 57.8902 32.7552 57.9625 32.604ZM59.3733 32.7552C59.301 32.6418 59.2286 32.5662 59.1563 32.4906C59.0477 32.415 58.9392 32.415 58.8307 32.415C58.7222 32.415 58.6137 32.4528 58.5051 32.4906C58.3966 32.5662 58.3243 32.6418 58.2881 32.7552C58.2157 32.8686 58.2157 32.982 58.2157 33.1332C58.2157 33.2844 58.2519 33.3978 58.2881 33.5112C58.3604 33.6246 58.4328 33.7002 58.5051 33.7758C58.6137 33.8514 58.7222 33.8514 58.8307 33.8514C58.9392 33.8514 59.0477 33.8136 59.1563 33.7758C59.2648 33.7002 59.3371 33.6246 59.3733 33.5112C59.4457 33.3978 59.4457 33.2844 59.4457 33.1332C59.4457 32.982 59.4095 32.8686 59.3733 32.7552Z"
        fill="black"
      />
      <path
        d="M60.6755 32.4533V33.6251C60.6755 33.7385 60.7116 33.7763 60.7478 33.8141C60.784 33.8519 60.8563 33.8897 60.9649 33.8897H61.1819V34.1543H60.8925C60.7116 34.1543 60.6031 34.1165 60.4946 34.0409C60.4222 33.9653 60.3499 33.8141 60.3499 33.6251V32.4533H60.0967V32.1887H60.3499V31.6973H60.6755V32.1887H61.1819V32.4533H60.6755Z"
        fill="black"
      />
      <path
        d="M61.5802 31.7725C61.544 31.7347 61.5078 31.6591 61.5078 31.6213C61.5078 31.5835 61.544 31.5079 61.5802 31.4701C61.6163 31.4323 61.6887 31.3945 61.7249 31.3945C61.7972 31.3945 61.8334 31.4323 61.8696 31.4701C61.9057 31.5079 61.9419 31.5835 61.9419 31.6213C61.9419 31.6591 61.9057 31.7347 61.8696 31.7725C61.8334 31.8103 61.7972 31.8481 61.7249 31.8481C61.6525 31.8481 61.6163 31.8481 61.5802 31.7725ZM61.8696 32.1883V34.1539H61.544V32.1883H61.8696Z"
        fill="black"
      />
      <path
        d="M62.7378 34.0397C62.5931 33.9641 62.4846 33.8507 62.4122 33.6995C62.3399 33.5483 62.3037 33.3593 62.3037 33.1703C62.3037 32.9813 62.3399 32.7923 62.4122 32.6411C62.4846 32.4899 62.5931 32.3765 62.7378 32.3009C62.8825 32.2253 63.0272 32.1875 63.2081 32.1875C63.3889 32.1875 63.5336 32.2253 63.6783 32.3009C63.823 32.3765 63.9315 32.4899 64.0039 32.6411C64.0762 32.7923 64.1124 32.9813 64.1124 33.1703C64.1124 33.3593 64.0762 33.5483 63.9677 33.6995C63.8954 33.8507 63.7507 33.9641 63.606 34.0397C63.4613 34.1153 63.2804 34.1531 63.0995 34.1531C63.0634 34.1909 62.8825 34.1531 62.7378 34.0397ZM63.5336 33.8129C63.6422 33.7751 63.7145 33.6617 63.7868 33.5483C63.8592 33.4349 63.8954 33.3215 63.8954 33.1325C63.8954 32.9435 63.8592 32.8301 63.823 32.7167C63.7507 32.6033 63.6783 32.5277 63.606 32.4521C63.5336 32.3765 63.3889 32.3765 63.2804 32.3765C63.1719 32.3765 63.0634 32.4143 62.9548 32.4521C62.8463 32.4899 62.774 32.6033 62.7378 32.7167C62.6655 32.8301 62.6655 32.9435 62.6655 33.1325C62.6655 33.2837 62.7016 33.4349 62.7378 33.5483C62.8101 33.6617 62.8825 33.7373 62.9548 33.8129C63.0634 33.8507 63.1357 33.8885 63.2804 33.8885C63.4251 33.8885 63.4613 33.8507 63.5336 33.8129Z"
        fill="black"
      />
      <path
        d="M66.1018 32.3401C66.2465 32.4913 66.3189 32.6803 66.3189 32.9827V34.1545H65.9933V33.0205C65.9933 32.8315 65.9571 32.6803 65.8486 32.5669C65.7401 32.4535 65.6316 32.4157 65.4507 32.4157C65.2698 32.4157 65.1613 32.4535 65.0528 32.5669C64.9443 32.6803 64.9081 32.8315 64.9081 33.0205V34.1167H64.5825V32.1511H64.9081V32.4157C64.9804 32.3023 65.0528 32.2267 65.1613 32.1889C65.2698 32.1511 65.3784 32.1133 65.523 32.1133C65.7763 32.1511 65.9571 32.2267 66.1018 32.3401Z"
        fill="black"
      />
      <path
        d="M69.6106 34.1537H69.285L68.0189 32.1503V34.1537H67.6934V31.6211H68.0189L69.285 33.6245V31.6211H69.6106V34.1537Z"
        fill="black"
      />
      <path
        d="M71.5279 31.6211V31.8857H70.4789V32.7173H71.3109V32.9819H70.4789V34.1159H70.1533V31.6211H71.5279Z"
        fill="black"
      />
      <path d="M73.373 31.6211V31.8857H72.7218V34.1159H72.3963V31.8857H71.7451V31.6211H73.373Z" fill="black" />
      <path
        d="M76.0856 32.9056C75.9409 33.0568 75.7239 33.0946 75.4706 33.0946H75.0004V34.153H74.6748V31.6582H75.4345C75.6877 31.6582 75.9047 31.7338 76.0494 31.8472C76.1941 31.9606 76.2665 32.1496 76.2665 32.3764C76.3026 32.5654 76.2303 32.7544 76.0856 32.9056ZM75.8324 32.7166C75.9047 32.641 75.9409 32.5276 75.9409 32.3764C75.9409 32.074 75.76 31.9228 75.4345 31.9228H74.9642V32.83H75.4345C75.6153 32.83 75.76 32.7922 75.8324 32.7166Z"
        fill="black"
      />
      <path d="M76.9901 31.4688V34.1525H76.6646V31.4688H76.9901Z" fill="black" />
      <path
        d="M77.5328 32.604C77.6052 32.4528 77.7137 32.3394 77.8584 32.2638C78.0031 32.1882 78.1478 32.1504 78.3287 32.1504C78.5095 32.1504 78.6542 32.1882 78.7628 32.2638C78.8713 32.3394 78.9798 32.415 79.0522 32.5284V32.1504H79.3777V34.116H79.0522V33.738C78.9798 33.8514 78.9075 33.9648 78.7628 34.0404C78.6542 34.116 78.5095 34.1538 78.3287 34.1538C78.1478 34.1538 78.0031 34.116 77.8584 34.0404C77.7137 33.9648 77.6052 33.8514 77.5328 33.6624C77.4605 33.4734 77.4243 33.3222 77.4243 33.1332C77.4243 32.9442 77.4605 32.7552 77.5328 32.604ZM78.9075 32.7552C78.8351 32.6418 78.7628 32.5662 78.6904 32.4906C78.5819 32.415 78.4734 32.415 78.3648 32.415C78.2563 32.415 78.1478 32.4528 78.0393 32.4906C77.9308 32.5662 77.8584 32.6418 77.8222 32.7552C77.7499 32.8686 77.7499 32.982 77.7499 33.1332C77.7499 33.2844 77.7861 33.3978 77.8222 33.5112C77.8946 33.6246 77.9669 33.7002 78.0393 33.7758C78.1478 33.8514 78.2563 33.8514 78.3648 33.8514C78.4734 33.8514 78.5819 33.8136 78.6904 33.7758C78.7989 33.7002 78.8713 33.6246 78.9075 33.5112C78.9798 33.3978 78.9798 33.2844 78.9798 33.1332C78.9798 32.982 78.9798 32.8686 78.9075 32.7552Z"
        fill="black"
      />
      <path
        d="M80.2458 32.4533V33.6251C80.2458 33.7385 80.2819 33.7763 80.3181 33.8141C80.3543 33.8519 80.4266 33.8897 80.5352 33.8897H80.7522V34.1543H80.4628C80.282 34.1543 80.1734 34.1165 80.0649 34.0409C79.9926 33.9653 79.9202 33.8141 79.9202 33.6251V32.4533H79.667V32.1887H79.9202V31.6973H80.2458V32.1887H80.7522V32.4533H80.2458Z"
        fill="black"
      />
      <path
        d="M81.8737 32.4529H81.4758V34.1539H81.1502V32.4529H80.897V32.1883H81.1502V32.0371C81.1502 31.8103 81.2225 31.6591 81.3311 31.5457C81.4396 31.4323 81.6205 31.3945 81.8737 31.3945V31.6591C81.729 31.6591 81.6566 31.6969 81.5843 31.7347C81.5119 31.7725 81.5119 31.8859 81.5119 31.9993V32.1505H81.9098V32.4529H81.8737Z"
        fill="black"
      />
      <path
        d="M82.5972 34.0397C82.4525 33.9641 82.344 33.8507 82.2716 33.6995C82.1993 33.5483 82.1631 33.3593 82.1631 33.1703C82.1631 32.9813 82.1993 32.7923 82.2716 32.6411C82.344 32.4899 82.4525 32.3765 82.5972 32.3009C82.7419 32.2253 82.8866 32.1875 83.0674 32.1875C83.2483 32.1875 83.393 32.2253 83.5377 32.3009C83.6824 32.3765 83.7909 32.4899 83.8633 32.6411C83.9356 32.7923 83.9718 32.9813 83.9718 33.1703C83.9718 33.3593 83.9356 33.5483 83.8271 33.6995C83.7548 33.8507 83.6101 33.9641 83.4654 34.0397C83.3207 34.1153 83.1398 34.1531 82.9589 34.1531C82.8866 34.1909 82.7419 34.1531 82.5972 34.0397ZM83.393 33.8129C83.5015 33.7751 83.5739 33.6617 83.6462 33.5483C83.7186 33.4349 83.7547 33.3215 83.7547 33.1325C83.7547 32.9435 83.7186 32.8301 83.6824 32.7167C83.6101 32.6033 83.5377 32.5277 83.4654 32.4521C83.393 32.3765 83.2483 32.3765 83.1398 32.3765C83.0313 32.3765 82.9227 32.4143 82.8142 32.4521C82.7057 32.4899 82.6333 32.6033 82.5972 32.7167C82.5248 32.8301 82.5248 32.9435 82.5248 33.1325C82.5248 33.2837 82.561 33.4349 82.5972 33.5483C82.6695 33.6617 82.7419 33.7373 82.8142 33.8129C82.9227 33.8507 82.9951 33.8885 83.1398 33.8885C83.2845 33.8885 83.2845 33.8507 83.393 33.8129Z"
        fill="black"
      />
      <path
        d="M84.985 32.226C85.0936 32.1504 85.2021 32.1504 85.3468 32.1504V32.4906H85.2744C84.9127 32.4906 84.7318 32.6796 84.7318 33.0954V34.1538H84.4062V32.1882H84.7318V32.4906C84.8042 32.3772 84.9127 32.3016 84.985 32.226Z"
        fill="black"
      />
      <path
        d="M88.3849 32.2267C88.4934 32.3023 88.6019 32.3779 88.6743 32.5291C88.7466 32.6425 88.7828 32.7937 88.7828 32.9827V34.1545H88.4572V33.0205C88.4572 32.8315 88.4211 32.6803 88.3125 32.5669C88.204 32.4535 88.0955 32.4157 87.9508 32.4157C87.7699 32.4157 87.6614 32.4535 87.5529 32.5669C87.4444 32.6803 87.4082 32.8315 87.4082 33.0583V34.1545H87.0826V33.0205C87.0826 32.8315 87.0464 32.6803 86.9379 32.5669C86.8294 32.4535 86.7209 32.4157 86.5762 32.4157C86.3953 32.4157 86.2868 32.4535 86.1783 32.5669C86.0698 32.6803 86.0336 32.8315 86.0336 33.0583V34.1545H85.708V32.1889H86.0336V32.4913C86.1059 32.3779 86.1783 32.3023 86.2868 32.2645C86.3953 32.2267 86.5038 32.1889 86.6485 32.1889C86.7932 32.1889 86.9379 32.2267 87.0826 32.3023C87.1911 32.3779 87.2997 32.4913 87.372 32.6425C87.4082 32.4913 87.5167 32.3779 87.6252 32.3023C87.7338 32.2267 87.8785 32.1889 88.0232 32.1889C88.1678 32.1511 88.2764 32.1511 88.3849 32.2267Z"
        fill="black"
      />
      <path
        d="M11.9013 2.4003C11.6842 2.5515 11.5396 2.8539 11.5396 3.1185V20.2419V21.6405V35.8911L14.8676 38.4236C15.157 38.6504 15.5911 38.6504 15.8804 38.4236L17.2189 37.403L19.2085 35.8911V21.6783V20.2797V3.1563C19.2085 2.8539 19.0638 2.5893 18.8467 2.4381L17.2189 1.1907L15.9166 0.1701C15.6272 -0.0566999 15.1931 -0.0566999 14.9037 0.1701L11.9013 2.4003Z"
        fill="#006DFE"
      />
      <path
        d="M23.0791 7.27803V31.281C23.0791 31.9992 23.8749 32.4528 24.4537 31.9992L30.4225 27.501C30.6395 27.3498 30.7842 27.0474 30.7842 26.7828V11.7006C30.7842 11.3982 30.6395 11.1336 30.4225 10.9824L24.4537 6.52203C23.8749 6.10623 23.0791 6.52203 23.0791 7.27803Z"
        fill="#006DFE"
      />
      <path
        d="M7.70509 7.27803V31.281C7.70509 31.9992 6.90926 32.4528 6.33047 31.9992L0.361741 27.501C0.144697 27.3498 0 27.0474 0 26.7828V11.7006C0 11.3982 0.144697 11.1336 0.361741 10.9824L6.33047 6.52203C6.90926 6.10623 7.70509 6.52203 7.70509 7.27803Z"
        fill="#7799F2"
      />
    </Svg>
  );
};

export default Icon;
