import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box } from 'widgets';
import { useTranslation } from 'contexts/Localization';
import { useRouter } from 'next/router';
import { MenuLink, MenuSubEntry } from './MenuEntry';

const Wrapper = styled.div`
  .list-sub-item{
    position: absolute;
    z-index: 2;
    top: 111%;
    left: 20px;
    width: 100%;
    padding-left: 26px;
    padding-top: 10px;
    background-color: #F8F8F8;
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    & > .box-item{
      margin-bottom: 12px;
      padding: 5px;
      font-size: 18px;
      line-height: 22px;
      &.active{
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
`;

const SubListItem = ({ subMenu, toggleMenu }) => {
  const { t } = useTranslation()
  const [isShow, setIsShow] = useState(false)
  const wrapperRef = useRef(null);
  const router = useRouter()

  const handleRedirect = (link) => {
    if (link) {
      router.push(link)
      toggleMenu()
      setIsShow(false);
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Wrapper ref={wrapperRef} >
      <MenuSubEntry
        key={`sub-${subMenu.label}`}
      >
        <Box position="relative" onClick={() => setIsShow(true)}>
          <MenuLink >{t(subMenu.label)}</MenuLink>
          {
            isShow &&
            <Box className='list-sub-item'>
              {
                subMenu.list.map((item) =>
                  <Box className={`${item.href === router.pathname && 'active'} box-item`}>
                    <Box onClick={() => handleRedirect(item.href)}>{t(item.label)}</Box>
                  </Box>
                )
              }
            </Box>
          }

        </Box>
      </MenuSubEntry>
    </Wrapper>
  );
};

export default SubListItem;