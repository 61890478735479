import { Layout } from 'antd'
import Menu from 'components/Menu'
import Footer from 'components/Footer'

const { Content } = Layout

const PublicLayout = ({ children }) => {
  return (
    <Layout style={{ background: '#fff', minHeight: '100vh' }}>
      <Menu />
      <Content>
        <div>{children}</div>
      </Content>
      <Footer />
    </Layout>
  )
}

export default PublicLayout
