/* eslint-disable react/no-array-index-key */
import { Tooltip } from 'antd'
import { useTranslation } from 'contexts/Localization'
import { getContactContentChat } from 'hooks/api'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box, Flex, Link } from 'widgets'

interface FooterProps {
  isHidden?: boolean
}
const Wrapper = styled.div<FooterProps>`
  padding: 19px 0 36px;
  border-top: 1px solid #bbbbbb50;
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  z-index: 1;
  .container {
    justify-content: space-between;
    flex-direction: column-reverse;

    ${({ theme }) => theme.mediaQueries.lg} {
      flex-direction: row;
    }
  }

  .left-content {
    .text-rights {
      color: var(--Black, var(--en, #000));
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 35px;
      text-align: center;
      ${({ theme }) => theme.mediaQueries.lg} {
        margin-top: 35px;
        text-align: left;
      }
    }
    & > img {
      width: 135px;
      display: none;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
      & > img {
        display: block;
      }
    }
  }

  .right-content {
    color: var(--Black, var(--en, #000));
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .footer-logo {
      display: block;
      ${({ theme }) => theme.mediaQueries.lg} {
        display: none;
      }
    }

    .social-network {
      margin-top: 30px;
      flex-wrap: wrap;
      display: flex;
      gap: 1rem;
      justify-content: center;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
      align-items: flex-end;
    }

    .redirect-link {
      width: 350px;
      max-width: 623px;

      justify-content: space-evenly;
      ${({ theme }) => theme.mediaQueries.sm} {
        width: 623px;
        justify-content: space-between;
      }
    }

    .link-item {
      white-space: nowrap;
      margin-top: 20px;
      ${({ theme }) => theme.mediaQueries.lg} {
        margin-top: 0;
      }
    }
    .link-item:not(:last-child) {
    }

    .item-footer {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      position: relative;
      background: #bbbbbb;
      &.twitter-new img {
      }
      img {
        width: 24px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        background: #006dfe;
        img {
          filter: unset;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
`

const Footer = () => {
  const { t } = useTranslation()
  const [isHidden, setIsHidden] = useState(false)
  const { socialLinkData } = useSelector((state: any) => state.socialLink)

  const socialList = socialLinkData
    ?.filter((item) => item.type !== 'Linktr' && item.type !== 'TelegramGroup')
    ?.map((item) => {
      if (item.type === 'TelegramChannel') {
        return {
          ...item,
          name: 'Telegram',
        }
      }

      if (item.type === 'Linkedin') {
        return {
          ...item,
          name: 'LinkedIn',
        }
      }

      return {
        ...item,
        name: item.type,
      }
    })

  return (
    <Wrapper isHidden={isHidden}>
      <Flex className="container">
        <Box className="left-content">
          <img src="/images/logo.png" alt="" />

          <Box className="text-rights">{t('© 2023 Soei Group Ltd. All rights reserved.')}</Box>
        </Box>

        <Box className="right-content">
          <Box className="footer-logo">
            <img width={135} src="/images/logo.png" alt="" />
          </Box>

          <Flex flexWrap="wrap" justifyContent="center" className="redirect-link">
            <Link href="/about-us" className="link-item">
              {t('Về chúng tôi')}
            </Link>

            <Link href="/policy" className="link-item">
              {t('Điều khoản')}
            </Link>

            <Link href="/recruitment" className="link-item">
              {t('Tuyển dụng')}
            </Link>

            <Link href="/partner" className="link-item">
              {t('Dành cho đối tác')}
            </Link>

            <Link href="/contact" className="link-item">
              {t('Liên hệ và hỗ trợ')}
            </Link>
          </Flex>

          <Flex className="social-network">
            {socialList?.length > 0 &&
              socialList?.map((item, index) => (
                <Tooltip placement="bottom" color="#ffffff" title={<p className="tooltip-footer">{item?.name}</p>}>
                  <a className="item-footer" href={item?.link} target="_blank" rel="noreferrer">
                    <img
                      width={16}
                      height={16}
                      src={`/images/social-network/${item?.type?.toLowerCase()}.svg`}
                      alt=""
                    />
                  </a>
                </Tooltip>
              ))}
          </Flex>
        </Box>
      </Flex>
    </Wrapper>
  )
}

export default Footer
