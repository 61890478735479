import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { NETWORK_URL } from 'connectors'
import { NETWORK } from 'connectors/network'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Web3 from 'web3'
import { useDispatch, useSelector } from 'react-redux'
import { setBalanceData } from 'state/balances/actions'
import { calculateBalance, calculateValue } from 'utils'
import useGetTransactionHistory from './useGetTransactionHistory'
import { AppState } from '../state/index'

let timerGetNetworkBalance
const startblock = 0
let contractAddresses
const minABI: any = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    type: 'function',
  },
  // decimals
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    type: 'function',
  },
  // allowance
  {
    constant: true,
    inputs: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: 'amount', type: 'uint256' }],
    type: 'function',
  },
  // approve
  {
    constant: true,
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', type: 'bool' }],
    type: 'function',
  },
]

async function getCoingeckoPrice(address) {
  return fetch(`https://api.coingecko.com/api/v3/coins/binance-smart-chain/contract/${address.toLowerCase()}`)
    .then((response) => response.json())
    .then((token) => {
      return token
    })
    .catch(() => {
      //
    })
}
const sortWallet = (a, b) => {
  // then sort by price value
  if (a.price && b.price) {
    if (a.value * a.price > b.value * b.price) return -1
    if (a.value * a.price < b.value * b.price) return 1
  }
  if (!a.price && b.price) return 1
  if (a.price && !b.price) return -1
  // then sort by name
  return a?.tokenName?.localeCompare(b?.tokenName)
}

const formatBalance = (obj) => {
  const { balance, tokenDecimal, price, ...props } = obj
  try {
    return {
      ...props,
      _balance: balance,
      _value: props?.value,
      price,
      balance: calculateBalance(balance, tokenDecimal),
      value: calculateValue(balance, price, tokenDecimal),
    }
  } catch (error) {
    return obj
  }
}

const sortBalances = () => {
  return Object.values({ ...contractAddresses }).sort(sortWallet)
}

const useGetBalance = () => {
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  // const [balances, setBalances]: any = useState({})
  const [data]: any = useGetTransactionHistory()
  const balances = useSelector((state: AppState) => state.balances.balances)

  const getNetworkPrice = useCallback(async () => {
    const network = NETWORK.BSC
    const priceObj = await getCoingeckoPrice(network.tokenPriceContract)

    contractAddresses[network.tokenPriceContract] = formatBalance({
      ...(contractAddresses[network.tokenPriceContract] || {}),
      ...(priceObj
        ? {
            contract_address: priceObj?.contract_address,
            price: priceObj?.market_data?.current_price?.usd,
            icon: priceObj?.image?.small,
            priceChange: priceObj?.market_data?.price_change_24h_in_currency?.usd,
            priceChangePercentage: priceObj?.market_data?.price_change_percentage_24h_in_currency?.usd,
          }
        : {}),
    })
    dispatch(setBalanceData({ data: sortBalances() }))
  }, [dispatch])

  const getNetworkBalance = useCallback(async () => {
    try {
      const network = NETWORK.BSC
      const web3 = new Web3(NETWORK_URL)
      web3.eth.getBalance(account as any).then(
        (balance) => {
          if (balance) {
            contractAddresses[network.tokenPriceContract] = formatBalance({
              ...network,
              ...(contractAddresses[network.tokenPriceContract] || {}),
              balance,
            })
            dispatch(setBalanceData({ data: sortBalances() }))
          }

          clearTimeout(timerGetNetworkBalance)
          timerGetNetworkBalance = setTimeout(() => getNetworkBalance(), 10000)
        },
        (error) => {
          clearTimeout(timerGetNetworkBalance)
          timerGetNetworkBalance = setTimeout(() => getNetworkBalance(), 10000)
        },
      )
    } catch (error) {
      //
    }
  }, [account, dispatch])

  useEffect(() => {
    if (account) {
      contractAddresses = {
        // '0x5F2D91c698f2Bc1Fd9E4a92b1fcdA4D4aD17e0d3': {
        //   tokenSymbol: 'SF',
        //   tokenName: 'SphynxFi',
        //   balance: 0,
        //   value: 0,
        //   icon: '/images/logo.png',
        // },
      }
      getNetworkPrice()
      getNetworkBalance()
    }
  }, [account, getNetworkBalance, getNetworkPrice])

  useEffect(() => {
    if (account) {
      data.forEach(async (tx) => {
        try {
          if (tx.contractAddress) {
            const contract = new new Web3(NETWORK_URL || 'https://bsc-dataseed.binance.org').eth.Contract(
              minABI,
              tx.contractAddress,
            )

            if (contract) {
              const balance = await contract.methods.balanceOf(account).call()
              const parseBalance = balance

              const priceObj = await getCoingeckoPrice(tx.contractAddress)

              if (parseBalance !== contractAddresses[tx.contractAddress]?._balance) {
                contractAddresses[tx.contractAddress] = formatBalance({
                  balance: parseBalance,
                  ...tx,
                  ...(priceObj
                    ? {
                        contract_address: priceObj?.contract_address,
                        price: priceObj?.market_data?.current_price?.usd,
                        icon: priceObj?.image?.small,
                        priceChange: priceObj?.market_data?.price_change_24h_in_currency?.usd,
                        priceChangePercentage: priceObj?.market_data?.price_change_percentage_24h_in_currency?.usd,
                      }
                    : {}),
                })

                dispatch(setBalanceData({ data: sortBalances() }))
              }
            }
          }
        } catch (error) {
          //
        }
      })
    }
  }, [account, data, dispatch])

  useEffect(() => {
    if (!account) {
      clearTimeout(timerGetNetworkBalance)
      contractAddresses = {}
      dispatch(setBalanceData({ data: [] }))
    }
  }, [account, dispatch])

  return useMemo(() => balances.data, [balances.data])
}
export default useGetBalance
