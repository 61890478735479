/* eslint-disable react/jsx-boolean-value */
import { COUNTRY_LIST } from 'config/constants'
import ReactFlagsSelect from 'react-flags-select'
import styled from 'styled-components'

const WrapSelector = styled.div`
  .country-selector {
    button {
      padding: 15px 20px;
      border-radius: 10px;

      &:after {
        content: '';
        background-color: #000;
        -webkit-mask: url('/images/icons/arrow-right-01.svg');
        mask-image: url('/images/icons/arrow-right-01.svg');
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
        width: 13px;
        height: 21px;
        transition: all 300ms ease;
        position: absolute;
        right: 9px;
        transform: rotate(90deg) translateX(2px);
      }

      &[aria-expanded='true']:after {
        transform: rotate(-90deg) translateX(2px);
      }
    }

    #selected-country-US,
    #selected-country-VN,
    #selected-country-CN {
      svg {
        opacity: 0;
        position: relative;
      }

      span > span:first-child {
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    #selected-country-US {
      span > span:first-child {
        &:after {
          background-image: url('/images/flags/us.png');
        }
      }
    }

    #selected-country-VN {
      span > span:first-child {
        &:after {
          background-image: url('/images/flags/vn.png');
        }
      }
    }

    #selected-country-CN {
      span > span:first-child {
        &:after {
          background-image: url('/images/flags/cn.png');
        }
      }
    }

    span {
      position: relative;
      svg {
        font-size: 30px;
      }

      font-family: 'Familjen-Grotesk';
      font-weight: 400;
      font-size: 14px;
      color: #000;
      line-height: 25px;
    }

    ul {
      background: #fff;
      list-style-type: none;
      border: none;
      border-radius: 10px;
      box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.5);

      li {
        padding: 7px 5px 7px 13px;

        span {
          color: #000;
          font-weight: 700;
          line-height: 22px;
        }

        svg {
          opacity: 1;
        }

        &:hover {
          background-color: rgba(0, 109, 254, 0.2);
        }
      }
    }

    div {
      background: #fff;
      z-index: 100;
      box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.5);

      input {
        background: #fff;
        border: 1px solid #4d4d4d;
        color: #000;
        border-radius: 8px;
      }
    }
  }

  .country-US,
  .country-VN,
  .country-CN {
    svg {
      opacity: 0;
    }

    span[data-testid='selected-country-selected-flag'] {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  .country-US {
    span[data-testid='selected-country-selected-flag'] {
      &:after {
        background-image: url('/images/flags/us.png');
      }
    }

    #selected-country-btn {
      & > span:after {
        content: 'EN';
        left: 59%;
      }
    }

    #selected-country-US {
      position: relative;
      background-color: rgba(0, 109, 254, 0.2);
      &:after {
        content: '';
        position: absolute;
        height: 5px;
        width: 5px;
        border-radius: 5px;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        background-color: #006dfe;
      }
    }
  }

  .country-VN {
    span[data-testid='selected-country-selected-flag'] {
      &:after {
        background-image: url('/images/flags/vn.png');
      }
    }

    #selected-country-btn {
      & > span:after {
        content: 'VI';
      }
    }

    #selected-country-VN {
      position: relative;
      background-color: rgba(0, 109, 254, 0.2);
      &:after {
        content: '';
        position: absolute;
        height: 5px;
        width: 5px;
        border-radius: 5px;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        background-color: #006dfe;
      }
    }
  }

  .country-CN {
    span[data-testid='selected-country-selected-flag'] {
      &:after {
        background-image: url('/images/flags/cn.png');
      }
    }
    #selected-country-btn {
      & > span:after {
        content: 'CN';
      }
    }
  }

  #selected-country-btn {
    span > span:nth-child(2) {
      opacity: 0;
    }
    & > span {
      &:after {
        position: absolute;
        top: 50%;
        left: 49%;
        transform: translate(-50%, -50%);
        color: #006dfe;
        font-weight: 700;
        line-height: 22px;
      }
    }

    &:hover,
    &:focus {
      border-color: #006dfe;
      &:after {
        background-color: #006dfe;
      }
    }
  }
`

const CustomFlagsSelector = ({ selected, onSelect, className = '', ...props }) => {
  return (
    <WrapSelector>
      <ReactFlagsSelect
        countries={COUNTRY_LIST}
        className={
          selected === 'US'
            ? `country-selector country-US ${className}`
            : selected === 'VN'
            ? `country-selector country-VN ${className}`
            : selected === 'CN'
            ? `country-selector country-CN ${className}`
            : `country-selector ${className}`
        }
        selected={selected}
        searchable={true}
        onSelect={onSelect}
        rfsKey="selected-country"
        {...props}
      />
    </WrapSelector>
  )
}

export default CustomFlagsSelector
