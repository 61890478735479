import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { InputProps, scales } from './types'

interface StyledInputProps extends InputProps {
  theme: DefaultTheme
}

/**
 * Priority: Warning --> Success
 */
const getBoxShadow = ({ isSuccess = false, isWarning = false, theme }: StyledInputProps) => {
  if (isWarning) {
    return theme.shadows.warning
  }

  if (isSuccess) {
    return theme.shadows.success
  }

  return 'unset'
}

const getHeight = ({ scale = scales.MD }: StyledInputProps) => {
  switch (scale) {
    case scales.SM:
      return '20px'
    case scales.MD:
      return '28px'
    case scales.LG:
      return '42px'
    default:
      return '28px'
  }
}
const WrapperInput = styled.div<InputProps>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};

  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;

  width: 100%;
  padding: 4px 12px;
  border: ${({ border }) => border || '1px solid #262b34'};
  border-radius: 5px;
  box-shadow: ${getBoxShadow};

  &::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }

  input {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.04em;
    // text-shadow: 1px 7px 4px rgba(111, 27, 47, 0.35), 0px -1px 0px #b64034;

    flex: 1 0 auto;
    height: ${getHeight};

    background: transparent;
    border: unset;
    outline: unset;
    &:hover,
    &:focus {
      border: unset;
      outline: unset;
    }

    &::placeholder {
      color: #fff;
      font-size: 12px;
      font-family: 'Helvetica';
    }
  }

  .prefix {
    height: 100%;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    & > div {
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.04em;
      text-shadow: 1px 7px 4px rgba(111, 27, 47, 0.35), 0px -1px 0px #b64034;
      margin-right: 8px;
    }
    img {
      width: auto;
      height: calc(${getHeight} - 2px);
    }
  }
`

const Input = ({ className, icon, value, border, ...props }: { [t: string]: any }) => {
  return (
    <WrapperInput icon={icon} className={className} border={border}>
      <input value={value} {...props} />
      <div className="prefix">
        {icon && (
          <>
            {/* <div>...</div> */}
            <img src={icon} title="UKDog" alt="UKDog" />
          </>
        )}
      </div>
    </WrapperInput>
  )
}

export default Input
