import styled from 'styled-components'
import { Link } from 'widgets'

const MenuEntry = styled.div`
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    &:before {
      content: '';
      width: 15px;
      height: 15px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: #2af6ff;
      border-radius: 50%;
    }

    a {
      color: #ffffff;
      background: linear-gradient(90.94deg, #2af6ff 11.35%, #006dfe 115.81%);
      border-radius: 30px;
      padding-left: 30px;
    }
  }

  button {
    min-height: 70px;
    min-width: 170px;
  }
`
const MenuSubEntry = styled.div`
  padding-bottom: 10px;

  &.active a {
    color: #006dfe;
  }
`
const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #efefef;
  padding: 4px 0;
  width: 283px;
  min-height: 45px;
  border-radius: 30px;
  transition: 0.2s;

  &:hover {
    color: #ffffff;
    background: linear-gradient(90.94deg, #2af6ff 11.35%, #006dfe 115.81%);
    padding-left: 30px;
  }
`

export { MenuEntry, MenuSubEntry, MenuLink }
