import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface SearchTextType {
  searchText: string
  isLoading: boolean
}

const initialState: SearchTextType = {
  searchText: "",
  isLoading: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setSearchNews, (state, { payload }) => {
      state.searchText = payload
    }).
    addCase(actions.setIsSearchingNews, (state, { payload }) => {
      state.isLoading = payload
    }),
)
