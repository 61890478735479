import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import useIsMobile from 'hooks/useIsMobile'
import { LOCALIZATION_LIST } from 'config/constants'
import { Box, Flex } from '../../../packages/uikit/src/components/Box'
import LocalCard from './LocalCard'

const Wrapper = styled.div`
  margin-left: 10px;
  position: relative;
  .show-content {
    position: absolute;
    top: 120%;
    left: -56px;
    width: 117px;
    border-radius: 8px;
    padding: 15px 8px 10px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    transition: all 0.3s ease;
    animation: slide-fade-in 0.3s ease-out;

    &.breack-mobile {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      bottom: 136px;
    }
    .nav-bar {
      margin-bottom: 10px;
      div {
        cursor: pointer;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        border: 0.5px solid #000;
        text-align: center;
        border-radius: 5px;
        line-height: 30px;
        width: 47%;
        height: 30px;
        transition: all 0.3s ease;
        &.active {
          background: #006dfe;
          color: #fff;
          border-color: transparent;
        }
      }
    }

    .box-select {
      cursor: pointer;
      padding: 5px;
      border-radius: 11.5px;
      transition: all 0.3s ease;
      font-size: 13px;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      &:hover {
        color: #000;
        background: #fff;
      }
      &.active {
        color: #006dfe;
        background: rgba(0, 109, 254, 0.2);
      }
    }
  }
  .local-button {
    cursor: pointer;
    color: var(--6-d-6-d-6-d, var(--Grey-icon-button, #6d6d6d));
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    overflow: hidden;
    transition: all 0.3s ease;
    &:hover {
      width: 64px;
      opacity: 1;
    }
    &.expand-width {
      width: 64px;
      opacity: 1;
    }
    &.isScroll {
      background: var(--Bg-button, #e9e9e9);
    }
    text-transform: uppercase;
    & > p {
      padding-left: 4px;
      color: var(--6-d-6-d-6-d, var(--Grey-icon-button, #6d6d6d));

      overflow: hidden;
      width: 25px;
      white-space: nowrap;
    }
  }

  @keyframes slide-fade-in {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const LocalTooltip = ({ isScroll }) => {
  const [isShow, setIsShow] = useState(false)
  const [flag, setFlag] = useState(false)
  const wrapperRef = useRef(null)
  const wrapperRef2 = useRef(null)

  const isMobile = useIsMobile()
  const handleClick = () => {
    setIsShow((pre) => !pre)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        wrapperRef2.current &&
        !wrapperRef2.current.contains(event.target)
      ) {
        setIsShow(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const cacheLocalize = localStorage.getItem('localize')
    let _flag = LOCALIZATION_LIST[0].flag
    if (cacheLocalize) {
      const jsonLocalize = JSON.parse(cacheLocalize)
      _flag = jsonLocalize ? jsonLocalize.flag : LOCALIZATION_LIST[0].flag
    }
    setFlag(_flag as any)
  }, [])

  useEffect(() => {
    setIsShow(false)
  }, [flag])

  return (
    <Wrapper>
      <Box ref={wrapperRef}>
        {isShow && (
          <Box className={`show-content ${isMobile && 'breack-mobile'}`}>
            <LocalCard setParentFlag={setFlag} />
          </Box>
        )}
      </Box>

      <Flex
        className={`local-button ${isShow && 'expand-width'}  ${isScroll && 'isScroll'}`}
        onClick={handleClick}
        alignItems="center"
        background="#fff"
        height={25}
        borderRadius={7}
        ref={wrapperRef2}
      >
        <Box width={25}>
          <img width={25} src="/images/header/world.png" alt="" />
        </Box>
        <p>{flag}</p>
      </Flex>
    </Wrapper>
  )
}

export default LocalTooltip
