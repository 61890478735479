import { useTranslation } from 'contexts/Localization';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSearchingNews, setSearchNews } from 'state/searchNews/actions';
import styled from 'styled-components';
import { Box } from 'widgets';

const Wrapper = styled.div`
  max-width: 765px;
  width: 100%;
  .search-content{
    max-width: 765px;
    width: 100%;
    margin: 31px 0 30px;
    
    &.active{

      .recently-search{
        display: block;
      }

      .search-box{
        border-radius: 27px 27px 0 0;
      }
    }
    .search-box{
      width: 100%;
      background: var(--Trng, #FFF);
      backdrop-filter: blur(6px);
      border-radius: 27px;
      overflow: hidden;
      max-height: 51px;
      .search-input{
        padding: 16px 115px 16px 50px;
        color: var(--6-d-6-d-6-d, var(--Grey-icon-button, #6D6D6D));
        font-family: Inter;
        font-size: 16px;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        outline: none;
        border: none;
      }

      .search-image{
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }

      .search-button{
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        width: fit-content;
        border-radius: 52.053px;
        background: var(--Gr-2, linear-gradient(143deg, #006DFE 21.44%, #43E8E1 113.09%));
        color: var(--Trng, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 9px 22px;
        cursor: pointer;
        &:hover{
          opacity: 0.8;
        }
      }
    }

    .recently-search{
        display: none;
        /* position: absolute; */
        z-index: 1;
        width: 100%;
        
        border-radius: 0 0 27px 27px;
        background: var(--Trng, #FFF);
        backdrop-filter: blur(6px);
        .recently-search-list{
          padding: 17px 50px 30px;
          border-top: 1px solid var(--Gr-2, #BBBBBB80);
          color: var(--6-d-6-d-6-d, var(--Grey-icon-button, #6D6D6D));
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          .recently-search-item{
            cursor: pointer;
            &:not(:last-child){
              margin-bottom: 15px;
            }
            &:hover{
              opacity: 0.8;
            }
          }
        }
      }
  }
    
`;

const SearchBar = ({ isShowSearch }) => {
  const { t } = useTranslation()
  const [isShowRecentSearch, setIsShowRecentSearch] = useState(false);

  const dispatch = useDispatch()
  const { searchText } = useSelector((state: any) => state.searchNews)

  const MAX_RECENT_SEARCHES = 5;
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    const storedRecentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];
    setRecentSearches(storedRecentSearches.slice(0, MAX_RECENT_SEARCHES));
  }, [isShowSearch]);

  useEffect(() => {
    localStorage.setItem('recentSearches', JSON.stringify(recentSearches.slice(0, MAX_RECENT_SEARCHES)));
  }, [recentSearches]);

  const handleSearch = () => {
    dispatch(setIsSearchingNews(true))

    if (!recentSearches.includes(searchText)) {
      setRecentSearches((prevSearches) => [searchText, ...prevSearches.slice(0, MAX_RECENT_SEARCHES - 1)]);
    }
  };

  const handleSearching = (e) => {
    dispatch(setSearchNews(e.target.value))
    if (e.target.value.length > 0) {
      setIsShowRecentSearch(true)
    }
    else {
      setIsShowRecentSearch(false)
    }
  }

  const handleSelectHintSearch = (text) => {
    dispatch(setSearchNews(text))
    setIsShowRecentSearch(false)
  }

  useEffect(() => {
    if (!isShowSearch) {
      dispatch(setSearchNews(''))
      setIsShowRecentSearch(false)
    }
  }, [dispatch, isShowSearch])

  return (
    <Wrapper>
      <Box position='relative' className={`${isShowRecentSearch && 'active'} search-content`}>
        <Box position='relative' className='search-box'>
          <img className='search-image' src='/images/news/search.svg' alt='' />
          <input value={searchText} type="text" className='search-input' onChange={handleSearching} placeholder={t('Tìm kiếm tin tức')} />
          <Box className='search-button' onClick={handleSearch}>
            {t('Search')}
          </Box>
        </Box>

        <Box className='recently-search'>

          <Box className='recently-search-list'>
            {
              recentSearches.slice(0, 3).map((item) => (
                <Box className='recently-search-item' onClick={() => handleSelectHintSearch(item)}>
                  {item}
                </Box>
              ))
            }
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default SearchBar;