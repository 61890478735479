import { Tooltip } from 'antd';
import { useTranslation } from 'contexts/Localization';

const ComingSoonTooltip = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      placement="bottom"
      color="#ffffff"
      title={
        <p className="tooltip-footer">
          {t('Coming Soon')}
        </p>
      }
    >
      <div style={{ width: 'fit-content' }}>
        {children}
      </div>
    </Tooltip>
  );
};

export default ComingSoonTooltip;