import styled from 'styled-components'
import { useRouter } from 'next/router'
import LocalizeSelector from 'components/LocalizeSelector'
import { useTranslation } from 'contexts/Localization'
import { useSelector } from 'react-redux'
import { Box, Flex } from 'widgets'
import Accordion from './Accordion'
import { MenuEntry, MenuLink } from './MenuEntry'
import { MenuEntry as MenuEntryTypes } from './types'
import CollapseIcon from './CollapseIcon'
import SubListItem from './SubListItem'

const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 20px 2rem;
  z-index: 900;
  overflow: auto;
  transition: 0.25s;
  transform: ${({ visible }) => (visible ? 'translateX(0)' : 'translateX(100%)')};
  background: #006dfe;

  ul {
    #selected-country-btn {
      border: 0.7px solid #efefef;

      > span:after {
        color: #efefef;
      }

      &:hover,
      &:focus {
        border: 0.7px solid #efefef;
      }
    }

    .country-selector button:after {
      background-color: #fff;
      -webkit-mask: url('/images/icons/arrow-right-02.svg');
      mask-image: url('/images/icons/arrow-right-02.svg');
    }
  }

  .down-button {
    z-index: 1;
    margin-left: 10px;
    width: fit-content;
    border-radius: 21px;
    border: 1px solid #fff;

    background: var(--Gr-2, linear-gradient(143deg, #006dfe 21.44%, #43e8e1 113.09%));

    color: #fff;
    text-align: center;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    padding: 9px 22px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .social-network {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
    min-height: 67px;
    padding: 20px 15px;
    border: 1px solid #2af6ff;
    border-radius: 30px;
    width: 100%;
    margin-top: 32px;
    .twitter {
      background: #bfd5f2;
      width: 58px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 23px !important;
        height: 23px !important;
        filter: invert(73%) sepia(80%) saturate(2000%) hue-rotate(210deg) brightness(100%) contrast(100%);
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 58px;
      height: 42px;

      padding: 0 8px;
      text-align: center;

      img {
        width: 100%;
        max-width: 42px;
        opacity: 0.8;
        transition: 0.25s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`

interface Props {
  visible: boolean
  links: MenuEntryTypes[]
  accountEllipsis?: string
  toggleWallet: () => void
  toggleMenu: () => void
  showSlider: boolean
}

const MobileSlider = ({ visible, links, accountEllipsis, toggleWallet, toggleMenu, showSlider }: Props) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { socialLinkData } = useSelector((state: any) => state.socialLink)

  const socialList = socialLinkData
    ?.filter((item) => item?.type !== 'TelegramGroup' && item?.type !== 'Linktr')
    ?.map((item) => {
      if (item.type === 'TelegramGroup' && item.type !== 'Discord') {
        return {
          ...item,
          type: 'Telegram',
        }
      }
      return item
    })

  return (
    <>
      <Wrapper visible={visible}>
        <CollapseIcon
          active={showSlider}
          toggleMenu={toggleMenu}
          styles={{ position: 'absolute', top: '40px', right: '35px' }}
        />

        <ul>
          <Flex>
            <LocalizeSelector />
            {router?.pathname !== '/mobile' && (
              <Box
                className="down-button"
                onClick={() => {
                  router.push('/mobile')
                  toggleMenu()
                }}
              >
                {t('Download App')}
              </Box>
            )}
          </Flex>
          {links.map((entry: any) => {
            const paths = router.pathname.split('/').map((path) => `/${path}`)
            const isActive = entry.actives.includes(paths[1])
            if (entry.items) {
              return (
                <Accordion
                  key={`menu-${entry.label}`}
                  label={t(entry.label)}
                  isActive={isActive}
                  initialOpenState={visible}
                >
                  {entry.items.map((subMenu) => {
                    return <SubListItem subMenu={subMenu} toggleMenu={toggleMenu} />
                  })}
                </Accordion>
              )
            }
            return (
              <MenuEntry
                key={`menu-${entry.label}`}
                className={paths.includes(entry.href || '') ? 'active' : ''}
                onClick={toggleMenu}
              >
                <MenuLink href={entry.href}>{t(entry.label)}</MenuLink>
              </MenuEntry>
            )
          })}
        </ul>

        <div className="social-network">
          {socialList?.map((item) => {
            const { type, link } = item
            return (
              <a className="comming-soon tele-group" href={link} target="_blank" rel="noreferrer">
                <div className={`${type?.toLowerCase()}`}>
                  <img src={`/images/social-network/v2/${type?.toLowerCase()}.svg`} alt="" />
                </div>
              </a>
            )
          })}
        </div>
      </Wrapper>
    </>
  )
}
export default MobileSlider
