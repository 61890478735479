import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    // <Svg viewBox="0 0 24 24" {...props}>
    //   <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z" />
    // </Svg>
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="20" cy="20" r="19.5" stroke="white" />
      <path
        d="M12.3305 12.3305C12.435 12.2257 12.5592 12.1426 12.6958 12.0859C12.8325 12.0292 12.979 12 13.127 12C13.275 12 13.4215 12.0292 13.5582 12.0859C13.6949 12.1426 13.819 12.2257 13.9235 12.3305L19.877 18.2863L25.8305 12.3305C25.9351 12.2259 26.0593 12.1429 26.1959 12.0863C26.3326 12.0297 26.4791 12.0006 26.627 12.0006C26.7749 12.0006 26.9214 12.0297 27.0581 12.0863C27.1947 12.1429 27.3189 12.2259 27.4235 12.3305C27.5281 12.4351 27.6111 12.5593 27.6677 12.6959C27.7243 12.8326 27.7534 12.9791 27.7534 13.127C27.7534 13.2749 27.7243 13.4214 27.6677 13.5581C27.6111 13.6947 27.5281 13.8189 27.4235 13.9235L21.4678 19.877L27.4235 25.8305C27.5281 25.9351 27.6111 26.0593 27.6677 26.1959C27.7243 26.3326 27.7534 26.4791 27.7534 26.627C27.7534 26.7749 27.7243 26.9214 27.6677 27.0581C27.6111 27.1947 27.5281 27.3189 27.4235 27.4235C27.3189 27.5281 27.1947 27.6111 27.0581 27.6677C26.9214 27.7243 26.7749 27.7534 26.627 27.7534C26.4791 27.7534 26.3326 27.7243 26.1959 27.6677C26.0593 27.6111 25.9351 27.5281 25.8305 27.4235L19.877 21.4678L13.9235 27.4235C13.8189 27.5281 13.6947 27.6111 13.5581 27.6677C13.4214 27.7243 13.2749 27.7534 13.127 27.7534C12.9791 27.7534 12.8326 27.7243 12.6959 27.6677C12.5593 27.6111 12.4351 27.5281 12.3305 27.4235C12.2259 27.3189 12.1429 27.1947 12.0863 27.0581C12.0297 26.9214 12.0006 26.7749 12.0006 26.627C12.0006 26.4791 12.0297 26.3326 12.0863 26.1959C12.1429 26.0593 12.2259 25.9351 12.3305 25.8305L18.2863 19.877L12.3305 13.9235C12.2257 13.819 12.1426 13.6949 12.0859 13.5582C12.0292 13.4215 12 13.275 12 13.127C12 12.979 12.0292 12.8325 12.0859 12.6958C12.1426 12.5592 12.2257 12.435 12.3305 12.3305Z"
        fill="white"
      />
    </svg>
  );
};

export default Icon;
