import { useWeb3React } from '@web3-react/core'
import useIsMobile from 'hooks/useIsMobile'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import styled from 'styled-components'
import { Box, Flex, Link } from 'widgets'
import { useTranslation } from 'contexts/Localization'
import LocalTooltip from 'components/LocalTooptip'
import { setShowMouse } from 'state/mouse/actions'
import ComingSoonTooltip from 'components/ComingSoonTooltip'
import CollapseIcon from './CollapseIcon'
import MobileSlider from './MobileSlider'
import { ourProducts } from './config'
import SearchBar from './SearchBar'

const WrapperHeader = styled.header<{ background: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 75px;
  z-index: 999;
  position: fixed;

  background: ${({ background }) => background};

  box-shadow: ${({ background }) =>
    background === 'transparent' ? `none` : `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`};

  &.active {
    display: none;
    background: transparent;
    box-shadow: none;

    .content {
      .menu-left {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .content {
    position: relative;
    max-width: 1300px;
    padding: 10px 25px 10px 10px;
    margin: 0 auto;
    width: 100%;

    display: flex;
    justify-content: space-between;

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 13px;
    }

    .menu-left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 70px;
        min-width: 70px;

        ${({ theme }) => theme.mediaQueries.xl} {
          width: 120px;
          min-width: 120px;
        }
      }

      .menu-list {
        width: 100%;
        display: flex;
        align-items: center;
        list-style: none;
        flex-direction: column;
        position: relative;
        margin: 0 15px 0 90px;

        &.active li .coming-soon-text,
        &.active li a,
        &.active .sub-menu {
          color: var(--en, #000);
        }

        li {
          &:not(:last-child) {
            margin-right: 70px;
          }

          .coming-soon-text {
            color: var(--Trng, #fff);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            display: block;
          }

          a {
            color: var(--Trng, #fff);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            display: block;

            &:hover {
              color: #006dfe;
            }
          }

          &.active a {
            font-weight: 600;
            color: #006dfe;
          }
        }
      }
    }

    .menu-right {
      display: flex;
      align-items: center;

      .notify-icon {
        width: 30px;
        position: relative;
        margin-right: 16px;
        cursor: pointer;

        span {
          position: absolute;
          top: 2px;
          right: -3px;
          width: 18px;
          height: 18px;
          background: #f41706;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 100%;
        }
      }

      .menu-actions-desktop {
        position: relative;
        display: flex;
        align-items: center;
        transition: all 0.3s ease;
        .line-box {
          width: 1px;
          height: 100%;
          height: 34px;
          margin: 0 22px;
          background: #6d6d6d;
        }

        .show-search-header {
          width: 0;
          overflow: hidden;
          transition: width 0.3s ease;
          &.active {
            width: 100%;
          }
        }

        .search-header {
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }

        button {
          &:not(:last-child) {
            margin-right: 13px;
          }
        }
      }

      .open-app-btn {
        min-width: 150px;
        margin-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
      }
    }
  }

  &.show-sub-tab {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(2px);
  }

  .sub-tab {
    width: 100vw;
    max-height: 0;
    overflow: hidden;
    z-index: 1010;
    position: absolute;
    background: #fff;

    &.home-sub-tab {
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(2px);
    }

    top: 100%;
    left: 0;
    &.active {
      max-height: 500px; /* Adjust to fit your needs */
    }
    .subtab-list {
      padding: 20px 20px 20px 223px;
    }

    .subtab-box {
      &:not(:last-child) {
        margin-right: 40px;
      }
      .subtab-title {
        color: var(--en, #000);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 18px;
      }

      .subtab-link {
        color: var(--en, #000);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: fit-content;
        cursor: pointer;
        margin-bottom: 12px;
        position: relative;
        .dot {
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 0;
          height: 2px;
          border-radius: 2px 2px 0px 0px;
          background: var(--Main-blue, #006dfe);
          transition: width 0.3s;
        }

        &:hover .dot,
        &.active .dot {
          width: 100%;
        }
      }
    }
  }

  .sub-menu {
    position: relative;
    z-index: 1;
    color: var(--Trng, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    display: block;

    &:hover,
    &.active {
      color: #006dfe !important;
    }

    &.active::before {
      z-index: 1;
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 200%;
      height: 150%;
    }
  }

  .search-header-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: rgba(202, 225, 255, 0.85);
    backdrop-filter: blur(10px);

    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
    &.active {
      height: 239px;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      .close-button {
        position: absolute;
        top: 25px;
        right: 14px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .down-button {
    z-index: 1;
    width: fit-content;
    border-radius: 21px;

    background: var(--Gr-2, linear-gradient(143deg, #006dfe 21.44%, #43e8e1 113.09%));

    color: #fff;
    text-align: center;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    padding: 9px 22px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`
interface Props {
  menuItems: any
  hasSider?: boolean
}

const mobileWith = 1024

const Header: React.FC<Props> = ({ menuItems, hasSider = false }) => {
  const router = useRouter()
  const [isScroll, updateIsScroll] = useState(false)
  const [isShowSubTab, setIsShowSubTab] = useState(false)
  const [isShowSearch, setIsShowSearch] = useState(false)
  const [isShowSearchButton, setIsShowSearchButton] = useState(false)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const isMobile = useIsMobile(mobileWith)
  const { account } = useWeb3React()

  const handleToggleFalse = () => {
    dispatch(setShowMouse(false))
  }

  const handleToggleTrue = () => {
    dispatch(setShowMouse(true))
  }
  const [showSlider, setShowSlider] = useState(false)

  const toggleWallet = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const toggleMenu = () => {
    setShowSlider((prev) => !prev)
  }

  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : undefined
  const paths = router.pathname.split('/').map((path) => `/${path}`)

  useEffect(() => {
    if (router?.pathname === '/home' || router?.pathname === '/') {
      updateIsScroll(false)
    } else {
      updateIsScroll(true)
    }
  }, [router?.pathname])

  const handleHover = () => {
    setIsShowSubTab(true)
    handleToggleFalse()
  }

  const handleLeave = () => {
    handleToggleTrue()
    setIsShowSubTab(false)
  }

  const [isHeaderVisible, setHeaderVisible] = useState(true)
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout | null = null

    const handleScroll = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout)
      }

      scrollTimeout = setTimeout(() => {
        const currentScrollPos = window.pageYOffset

        setHeaderVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10)

        if (router?.pathname === '/news') {
          if (currentScrollPos > window.innerHeight) {
            setIsShowSearchButton(true)
          } else {
            setIsShowSearchButton(false)
          }
          setIsShowSearch(false)
        } else {
          setIsShowSearchButton(false)
        }

        if (router?.pathname === '/home' || router?.pathname === '/') {
          if (currentScrollPos > window.innerHeight) {
            updateIsScroll(true)
          } else {
            updateIsScroll(false)
          }
        }
        handleLeave()
        setPrevScrollPos(currentScrollPos)
      }, 50)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout)
      }
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos, router?.pathname])
  return (
    <>
      <WrapperHeader
        className={`${showSlider && 'active'}  ${isShowSubTab && 'show-sub-tab'}`}
        background={!isScroll ? 'transparent' : '#fff'}
        style={{
          transform: isHeaderVisible ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'transform 0.2s ease-in-out',
        }}
      >
        <div className="content">
          {!hasSider ? (
            <Flex flexDirection="column">
              <div className="menu-left">
                <Link href="/home" onMouseEnter={handleToggleFalse} onMouseLeave={handleToggleTrue}>
                  {!isMobile ? (
                    isScroll || isShowSubTab ? (
                      <img src="/images/logo.png" alt="" />
                    ) : (
                      <img src="/images/white-logo.png" alt="" />
                    )
                  ) : (
                    <img src="/images/logo.png" alt="" />
                  )}
                </Link>

                {!isMobile && (
                  <>
                    <div className={`${(isScroll || isShowSubTab) && 'active'} menu-list`}>
                      <Flex>
                        {menuItems.map((entry: any) => {
                          if (entry.items) {
                            return (
                              <li
                                key={entry.label}
                                className={`${isShowSubTab && 'active'} sub-menu`}
                                onMouseLeave={handleLeave}
                                onMouseOver={handleHover}
                                onFocus={handleHover}
                              >
                                {t(entry.label)}
                              </li>
                            )
                          }
                          return (
                            <li
                              key={entry.label}
                              className={paths.includes(entry.href || '') ? 'active' : ''}
                              onMouseEnter={handleToggleFalse}
                              onMouseLeave={handleToggleTrue}
                            >
                              {entry.href ? (
                                <Link href={entry.href} target={entry.target}>
                                  {t(entry.label)}
                                </Link>
                              ) : (
                                <div className="coming-soon-text">
                                  <ComingSoonTooltip>{t(entry.label)}</ComingSoonTooltip>
                                </div>
                              )}
                            </li>
                          )
                        })}
                      </Flex>
                    </div>
                  </>
                )}
              </div>
            </Flex>
          ) : (
            <div />
          )}

          <div className={`${isShowSubTab && 'active'} menu-right`}>
            {isMobile ? (
              <CollapseIcon active={showSlider} toggleMenu={toggleMenu} />
            ) : (
              <div className="menu-actions-desktop" onMouseEnter={handleToggleFalse} onMouseLeave={handleToggleTrue}>
                {router?.pathname !== '/mobile' && (
                  <Box
                    className="down-button"
                    onClick={() => {
                      router.push('/mobile')
                    }}
                  >
                    {t('Download App')}
                  </Box>
                )}

                <LocalTooltip isScroll={isScroll} />

                <Flex alignItems="center" className={`${isShowSearchButton && 'active'} show-search-header`}>
                  <Box className="line-box" />

                  <Box className="search-header" onClick={() => setIsShowSearch(true)}>
                    <img src="/images/news/search-normal.svg" alt="" />
                  </Box>
                </Flex>
              </div>
            )}
          </div>
        </div>

        <Box
          className={`${isShowSubTab && 'active'} ${'home-sub-tab'} sub-tab`}
          onMouseLeave={handleLeave}
          onMouseOver={handleHover}
        >
          <Flex className="subtab-list container">
            {ourProducts.map((item) => (
              <Box className="subtab-box">
                <Box className="subtab-title">{t(item.label)}</Box>

                {item.list.map((subItem) => (
                  <>
                    {subItem.href ? (
                      <a href={subItem.href} target={subItem.target} rel="noreferrer">
                        <Box className={`${router?.pathname === subItem.href && 'active'} subtab-link`}>
                          <div className="dot" />
                          {t(subItem.label)}
                        </Box>
                      </a>
                    ) : (
                      <Box className="subtab-link">
                        <ComingSoonTooltip>{t(subItem.label)}</ComingSoonTooltip>
                      </Box>
                    )}
                  </>
                ))}
              </Box>
            ))}
          </Flex>
        </Box>

        <Box className={`${isShowSearch && isHeaderVisible && 'active'} search-header-content`}>
          <Box className="container">
            <SearchBar isShowSearch={isShowSearch} />

            <Box className="close-button" onClick={() => setIsShowSearch(false)}>
              <img src="/images/news/close.svg" alt="" />
            </Box>
          </Box>
        </Box>
      </WrapperHeader>

      {isMobile && (
        <MobileSlider
          showSlider={showSlider}
          visible={showSlider}
          links={menuItems}
          accountEllipsis={accountEllipsis}
          toggleWallet={toggleWallet}
          toggleMenu={toggleMenu}
        />
      )}
    </>
  )
}

export default Header
