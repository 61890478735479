export const buildQueryString = (filter) => {
  const qs = []
  Object.keys(filter).forEach((key) => {
    if (!['', null, undefined].includes(filter[key])) {
      if (Array.isArray(filter[key])) {
        filter[key].forEach((el) => {
          qs.push(`${key}=${el}`)
        })
      } else {
        qs.push(`${key}=${filter[key]}`)
      }
    }
  })
  return `?${qs.join('&')}`
}
