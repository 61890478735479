import { MenuEntry } from './types'

/**
 * label: string
 * icon: svg
 * href: https || /path || 'function'
 * calloutClass: 'rainbow' || className => background rainbow and custom css
 * initialOpenState: true || false
 * items: array sample parent
 * att: attach => text || icon.png
 */

export const ourProducts = [
  {
    label: 'Ứng dụng',
    list: [
      {
        label: 'Soei',
        target: "",
        href: '/soei',
      },
      {
        label: 'Message',
        target: "",
        href: '/message',
      },
      {
        label: 'Fairium',
        target: "",
        href: '/fairium',
      },
      {
        label: 'Alumi (Ex)',
        target: "",
        href: '/alumi',
      },
      {
        label: 'Soei (Edu)',
        target: "",
        href: '/edu',
      },
      {
        label: 'eRetail',
        target: "",
        href: '/eretail',
      }

    ]
  },

  {
    label: 'Tiền mã hóa',
    list: [
      {
        label: 'SOI',
        target: "",
        href: '/soi-token',
      },
      {
        label: 'SOIR',
        target: "",
        href: '/soir-token',
      }
    ]
  },

  {
    label: 'Nền tảng',
    list: [
      {
        label: 'Mobile App',
        target: "",
        href: '/mobile',
      },
      {
        label: 'Web App',
        target: "",
        href: '',
      }
    ]
  },

  {
    label: 'Khác',
    list: [
      {
        label: 'Soei DAO',
        target: "",
        href: '/dao',
      },
      {
        label: 'Earn SOIR',
        target: "",
        href: '/earn-soir',
      }
    ]
  }
]


export const menuItems = [
  {
    label: 'Về chúng tôi',
    icon: 'history.svg',
    href: '/about-us',
    actives: ['/about-us'],
  },

  {
    label: 'Khám phá sản phẩm',
    icon: 'history.svg',
    href: '/brand',
    actives: ['/brand'],
    items: ourProducts
  },
  {
    label: 'Tài liệu',
    icon: 'document.svg',
    href: 'https://docs.soei.social/',
    target: '_blank',
    actives: ['/document'],
  },
  {
    label: 'Tin tức',
    icon: 'history.svg',
    href: '',
    actives: ['/news'],
  },

]

export default menuItems
