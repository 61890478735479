import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  -moz-user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  img {
    width: 100%;
    max-width: 48px;
    opacity: 0;
  }

  &.active {
    span {
      &:nth-child(1) {
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
          background: #efefef;
        }
      }

      &:nth-child(2) {
        &::before {
          content: unset;
        }
      }

      &:nth-child(3) {
        &::before {
          transform: translate(-50%, -50%) rotate(-45deg);
          background: #efefef;
        }
      }
    }
  }

  span {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 6px;
      background: #006dfe;
      border-radius: 20px;
      transition: 0.1s;
    }

    &:nth-child(1) {
      &::before {
        transform: translate(-50%, -125%);
      }
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      &::before {
        transform: translate(-50%, 75%);
      }
    }
  }
`

interface Props {
  active: boolean
  toggleMenu: () => void
  styles?: any
}

const CollapseIcon: React.FC<Props> = ({ active, toggleMenu, styles }) => {
  return (
    <Container className={active ? 'active' : ''} onClick={toggleMenu} style={styles}>
      <span />
      <span />
      <span />
      {/* <img src='/images/header/icon-menu.png' alt='' /> */}
    </Container>
  )
}

export default CollapseIcon
