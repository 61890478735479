import { Col, Row } from 'antd'
import { LOCALIZATION_LIST } from 'config/constants'
import { useState, useMemo } from 'react';
import { useTranslation } from 'contexts/Localization';
import { Box, Flex } from '../../../packages/uikit/src/components/Box'
import { Language } from '../../../packages/uikit/src/widgets/Menu';

const LocalCard = ({ setParentFlag }) => {
  const [flag, setFlag] = useState(LOCALIZATION_LIST[0].flag)
  const { setLanguage } = useTranslation()
  useMemo(() => {
    const cacheLocalize = localStorage.getItem('localize')
    let _flag = LOCALIZATION_LIST[0].flag
    if (cacheLocalize) {
      const jsonLocalize = JSON.parse(cacheLocalize)
      _flag = jsonLocalize ? jsonLocalize.flag : LOCALIZATION_LIST[0].flag
    }
    setFlag(_flag)
    setParentFlag(_flag)

  }, [])

  // update google translate selector
  const onChangeLocalize = (flagF) => {
    const localize = LOCALIZATION_LIST.filter((x) => x.flag === flagF)
    localStorage.setItem('localize', JSON.stringify(localize[0]))
    setParentFlag(flagF)
    setFlag(flagF)
    setLanguage({
      code: localize[0].lang,
      language: localize[0].lang,
      locale: localize[0].lang,
    } as Language)
  }
  return (
    <Row gutter={[10, 10]}>
      {
        LOCALIZATION_LIST.map((x) =>
          <Col span={24}>
            <Flex onClick={
              () => {
                onChangeLocalize(x.flag)
              }}
              className={`box-select ${x.flag === flag && 'active'}`}
              alignItems="center"
            >
              <img width={14} src={x.image} alt="" />
              <Box ml={2}>
                {x.name}
              </Box>
            </Flex>
          </Col>
        )
      }

    </Row>
  )
}

export default LocalCard