import { createReducer } from '@reduxjs/toolkit'
import { fetchInitialStateAsync } from './thunks/getSocialLink'

export interface SocialLinkState {
  socialLinkData: any
}

const initialState: SocialLinkState = {
  socialLinkData: []
}

export default createReducer(initialState, (builder) =>
  builder.addCase(fetchInitialStateAsync.fulfilled, (state, action) => {

    const filterData = action.payload.filter((item) => item?.type !== 'Website' && item?.type !== 'Docs')
    state.socialLinkData = filterData
  })
)
