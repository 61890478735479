import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ({ fill, ...props }) => {
  return (
    <Svg viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
      <path
        d="M21.2209 20.2639L21.2207 20.2637L18.2577 17.3068C19.7942 15.5498 20.7268 13.2507 20.7268 10.738C20.7268 5.22993 16.2468 0.75 10.7388 0.75C5.23072 0.75 0.749756 5.2299 0.749756 10.738C0.749756 16.2461 5.23069 20.727 10.7388 20.727C13.0142 20.727 15.1146 19.962 16.7956 18.6761L19.8076 21.6797L19.808 21.6801C20.0035 21.8743 20.2596 21.9717 20.5142 21.9717C20.769 21.9717 21.027 21.8747 21.2225 21.6769C21.613 21.2858 21.6109 20.6539 21.2209 20.2639ZM2.74976 10.738C2.74976 6.33311 6.33379 2.75 10.7388 2.75C15.1427 2.75 18.7268 6.33309 18.7268 10.738C18.7268 15.1429 15.1427 18.727 10.7388 18.727C6.33383 18.727 2.74976 15.1429 2.74976 10.738Z"
        fill={fill || '#919EAB'}
        stroke={fill || '#919EAB'}
        strokeWidth="0.5"
      />
    </Svg>
  )
}

export default Icon
