import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MenuEntry, MenuLink } from './MenuEntry'

const Container = styled.div``
const AccordionContent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? `unset` : 0)};
  transition: 0.3s ease-out;
  border-color: ${({ isOpen }) => (isOpen ? 'rgba(133, 133, 133, 0.1)' : 'transparent')};

  box-shadow: ${({ isOpen }) => (isOpen ? '-2px 2px 6px #2a5e6f' : 'none')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};

  & > div {
    padding-left: 20px;
    padding-top: 10px;
  }
`

const Accordion = ({ label, isActive, initialOpenState = false, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen((prevState) => !prevState)
  }
  useEffect(() => {
    if (!initialOpenState) {
      setIsOpen(false)
    }
  }, [initialOpenState])
  return (
    <Container>
      <MenuEntry key={label} className={isActive ? 'active' : ''} onClick={handleClick}>
        <MenuLink>{label}</MenuLink>
      </MenuEntry>
      <AccordionContent isOpen={isOpen}>
        <div>{children}</div>
      </AccordionContent>
    </Container>
  )
}

export default Accordion
