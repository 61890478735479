import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ({ fill, ...props }) => {
  return (
    <Svg width="21" height="23" viewBox="0 0 21 23" xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
      <path
        d="M3.10365 22.5511C2.90748 22.5026 2.71131 22.4629 2.51514 22.4055C1.05983 21.9863 0.0151075 20.5566 0.115474 19.1357C0.234088 17.5119 1.3883 16.2542 2.97135 16.0248C4.02063 15.8748 4.95586 16.113 5.75879 16.8014C5.78616 16.8235 5.81809 16.8455 5.85915 16.8764C8.62378 15.2923 11.3884 13.7125 14.1485 12.1328C14.0207 11.5062 14.0207 11.0031 14.1576 10.531C11.3975 8.98655 8.62834 7.44211 5.85003 5.88884C5.41207 6.2904 4.91024 6.56398 4.33085 6.69636C2.03612 7.23913 -0.0624481 5.58878 0.00142122 3.2986C0.0470422 1.80711 0.995958 0.584795 2.43758 0.156764C3.91114 -0.280091 5.50787 0.214129 6.37923 1.41438C6.96318 2.21308 7.14566 3.11326 6.94493 4.07082C6.90843 4.2385 7.01792 4.25615 7.1046 4.3091C9.47233 5.63291 11.8446 6.95671 14.2123 8.28052C14.4861 8.43496 14.7689 8.57617 15.0381 8.74385C15.1841 8.83652 15.2708 8.80563 15.3894 8.70414C16.8264 7.47741 19.2945 7.73776 20.3575 9.27779C21.1969 10.4913 21.206 11.793 20.4305 13.0374C19.6777 14.2421 18.4916 14.7186 17.0545 14.5951C16.352 14.5377 15.7361 14.2729 15.216 13.8096C15.0928 13.6993 15.0198 13.7655 14.924 13.8229C13.0764 14.8819 11.2333 15.9365 9.38565 16.9912C8.52342 17.4854 7.66574 17.9752 6.80351 18.4694C7.01793 19.3564 6.90387 20.1859 6.42029 20.9538C5.86828 21.8319 5.06079 22.3526 4.00694 22.5158C3.96132 22.5247 3.92026 22.5423 3.8792 22.5556C3.61916 22.5511 3.36369 22.5511 3.10365 22.5511Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
