import { useWeb3React } from '@web3-react/core'
import { ROOT_API, X_API_KEY } from 'config/constants'
import moment from 'moment'
import * as qs from 'query-string'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTransactionHistoryData } from 'state/balance-transactions/actions'
import { AppState } from '../state/index'

let timerGetTokenTx
let startblock = 0
let txHistory = []
const useGetTransactionHistory = () => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  // const txHistory = useSelector((state: AppState) => state.transactions.txHistory)

  const getData = useCallback(async () => {
    const qStr = qs.stringify({
      chain: 'bsc',
      offset: 10,
      from_block: startblock,
    })
    clearTimeout(timerGetTokenTx)
    timerGetTokenTx = setTimeout(() => getData(), 2600)
    try {
      const response: any = await (
        await fetch(`${ROOT_API}/${account}?${qStr}`, {
          headers: { 'x-api-key': X_API_KEY },
        })
      ).json()

      if (response?.total > 0) {
        const mappingResp = await response?.result.map((r) => ({
          ...r,
          blockNumber: r.block_number,
          from: r.from_address,
          to: r.to_address,
          gasPrice: r.gas_price,
          gasUsed: r.receipt_gas_used,
          transactionIndex: r.transaction_index,
          timeStamp: moment(r.block_timestamp),
          cumulativeGasUsed: r.receipt_cumulative_gas_used,
          contractAddress: r.receipt_contract_address,
          blockHash: r.block_hash,
        }))
        startblock = +mappingResp.at(0).blockNumber + 1
        txHistory = txHistory.concat(mappingResp)
        dispatch(setTransactionHistoryData({ data: [...txHistory] }))
      } else {
        return
      }
    } catch (error) {
      dispatch(setTransactionHistoryData({ data: [], page: 1, total: 0 }))
      console.error('Unable to fetch price data:', error)
    }
  }, [account, dispatch])

  useEffect(() => {
    if (account) {
      txHistory = []
      getData()
    }
  }, [account, getData])

  useEffect(() => {
    if (!account) {
      clearTimeout(timerGetTokenTx)
      dispatch(setTransactionHistoryData({ data: [], page: 1, total: 0 }))
    }
  }, [account, dispatch])

  return [txHistory, getData]
}
export default useGetTransactionHistory
