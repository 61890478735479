/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-shadow */
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import CustomFlagsSelector from 'components/CustomFlagsSelector'
import { LOCALIZATION_LIST } from 'config/constants'
import { useTranslation } from 'contexts/Localization'
import { Language } from '../../../packages/uikit/src/widgets/Menu'

const WrapSelector = styled.div`
  div {
    width: 100px;
    padding-bottom: 0;
  }

  #selected-country-btn {
    padding: 0;
    border: 1px solid #000;
    background: transparent;
    border-radius: 30px;
  }

  ul {
    overflow: hidden;
    width: 150px;

    &:-webkit-scrollbar {
      display: none;
    }
  }
`

const LocalizeSelector = ({ ...props }) => {
  // localize state
  const [flag, setFlag] = useState(LOCALIZATION_LIST[0].flag)
  const { setLanguage } = useTranslation()

  useMemo(() => {
    const cacheLocalize = localStorage.getItem('localize')
    let _flag = LOCALIZATION_LIST[0].flag
    if (cacheLocalize) {
      const jsonLocalize = JSON.parse(cacheLocalize)
      _flag = jsonLocalize ? jsonLocalize.flag : LOCALIZATION_LIST[0].flag
    }
    setFlag(_flag)
  }, [])

  // update google translate selector
  const onChangeLocalize = (flag) => {
    const localize = LOCALIZATION_LIST.filter((x) => x.flag === flag)
    localStorage.setItem('localize', JSON.stringify(localize[0]))

    setLanguage({
      code: localize[0].lang,
      language: localize[0].lang,
      locale: localize[0].lang,
    } as Language)
  }

  return (
    <WrapSelector>
      <CustomFlagsSelector
        countries={LOCALIZATION_LIST.map((x) => x.flag)}
        selected={flag}
        searchable={false}
        showSelectedLabel={!!true}
        customLabels={{ US: 'English', VN: 'Tiếng Việt', CN: 'Chinese' }}
        fullWidth={false}
        onSelect={(flag) => {
          setFlag(flag)
          onChangeLocalize(flag)
        }}
        {...props}
      />
    </WrapSelector>
  )
}

export default LocalizeSelector
